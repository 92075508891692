import React, { ReactElement } from "react";

import Icon from "components/Icon/Icon";

import { Content, Details, Summary } from "./FormAccordion.styled";
import FormAccordionProps from "./FormAccordionProps";

export const ComponentName = "FormAccordionBlock";

const FormAccordion = ({
    label,
    children,
}: FormAccordionProps): ReactElement => {
    return (
        <Details>
            <Summary>
                <Icon icon="arrowRight28" className="form-accordion-chevron" />
                {label}
            </Summary>
            <Content>{children}</Content>
        </Details>
    );
};

export default React.memo(FormAccordion);
