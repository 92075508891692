import { ComponentName as FormAccordionComponentName } from "../components/FormAccordion/FormAccordion";
import { ComponentName as FormContentComponentName } from "../components/FormContent/FormContent";
import FormElement from "../types/FormElement";
import { PropsWithEpiContent } from "../types/Props";

export const splitStepByAccordions = (
    stepItems: PropsWithEpiContent[],
): FormElement[] => {
    const result: FormElement[] = new Array<FormElement>();

    const createAccordionElement = (item: PropsWithEpiContent) => {
        result.push({
            item: item,
            children: new Array<PropsWithEpiContent>(),
        } as FormElement);
    };

    const handleContentElement = (item: PropsWithEpiContent) => {
        if (
            result[result.length - 1]?.item?.component ===
            FormAccordionComponentName
        )
            addElementToAccordion(item);
        else createUnparentedElement(item);
    };

    const createUnparentedElement = (item: PropsWithEpiContent) => {
        result.push({
            item: item,
        } as FormElement);
    };

    const addElementToAccordion = (item: PropsWithEpiContent) => {
        result[result.length - 1].children?.push(item);
    };

    stepItems.forEach((item) => {
        switch (item.component) {
            case FormAccordionComponentName:
                createAccordionElement(item);
                break;
            case FormContentComponentName:
                handleContentElement(item);
                break;
            default:
                createUnparentedElement(item);
                break;
        }
    });

    return result;
};
